<template>
  <div class="stake-box__membership--skeleton">
    <!-- <q-skeleton width="100%" height="74px" /> -->
    <q-inner-loading :showing="true">
      <q-spinner-gears size="50px" color="warning" />
    </q-inner-loading>
  </div>
</template>

<style lang="scss" scoped>
.stake-box__membership--skeleton {
  padding: 10px;
  width: 100%;
  margin: 0 auto;
  height: 96px;
  position: relative;

  .q-skeleton {
    border-radius: $border-radius;
  }
}
</style>
