<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import type { ChartData, ChartOptions } from 'chart.js'
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { getCssVar } from 'quasar'
import { Line as LineChart } from 'vue-chartjs'

defineProps({
  height: {
    type: String,
    default: '140px',
  },
})

Chart.register(
  LinearScale,
  LineElement,
  CategoryScale,
  PointElement,
  // Legend,
  Tooltip,
  annotationPlugin,
)

const currentDayIndex = ref(0)

const chartData = ref<ChartData<'line'>>({
  labels: [],
  datasets: [],
})

const DAILY_MS = 86400000

const membershipStore = useMembershipStore()
const boostStartDate = computed(() => membershipStore.boostStartDate + DAILY_MS)
const boostEndDate = computed(() => membershipStore.boostEndDate + DAILY_MS)

function setData() {
  const startDate = boostStartDate.value + DAILY_MS
  const endDate = boostEndDate.value + DAILY_MS
  const data: ChartData<'line'> = {
    labels: [],
    datasets: [{
      label: 'Booster',
      data: [],
    }],
  }

  const now = new Date()
  now.setUTCHours(0, 0, 0, 0)

  for (let day = startDate; day <= endDate; day += DAILY_MS) {
    const dailyRate = membershipStore.dateBoostRate(day)
    data.datasets[0]?.data.push(dailyRate)
    const dayNumber = (day - startDate) / DAILY_MS + 1
    data.labels?.push(`D${dayNumber}`)

    if (now.getTime() === day) {
      currentDayIndex.value = dayNumber - 1
    }
  }

  chartData.value = data
}

watch([boostStartDate, boostEndDate], () => {
  setData()
}, { immediate: true })

const chartOptions = computed<ChartOptions<'line'>>(() => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: '#239f90',
    backgroundColor: '#239f90',
    borderWidth: 3,
    pointRadius: 0,
    pointHoverRadius: 5,

    scales: {
      y: {
        min: 1,
        max: 2,
        offset: true,
        ticks: {
          stepSize: 0.5,
          color: (isDark.value ? '#D6DBDF' : getCssVar('primary')) ?? 'black',
          callback: (value, _index, _ticks) => `${value}x`,
          font: {
            size: 12,
          },
        },
        grid: {
          color: isDark.value ? '#425B66' : '#D6DBDF',
        },
      },
      x: {
        ticks: {
          maxRotation: 0,
          color: (isDark.value ? '#D6DBDF' : getCssVar('primary')) ?? 'black',
          font: {
            size: 12,
          },
        },
        grid: {
          color: isDark.value ? '#425B66' : '#D6DBDF',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title(context) {
            return context[0]?.label?.replace('D', 'Day ')
          },
          label(context) {
            return `${context?.formattedValue}x`
          },
        },
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          indexLine: {
            type: 'line',
            xMin: currentDayIndex.value,
            xMax: currentDayIndex.value,
            borderColor: '#ffcb25',
            borderWidth: 3,
          },
        },
      },
    },
  }
})
</script>

<template>
  <div v-if="chartData.datasets.length" class="hc-booster-chart relative-position" :style="{ height }">
    <line-chart
      :data="chartData"
      :options="chartOptions"
      :style="{ height, width: '100%' }"
    />
  </div>
</template>
