<script lang="ts" setup>
import tokenSvg from '~/assets/img/lightning-token.svg'

const membershipStore = useMembershipStore()
const currentBoostRate = computed(() => membershipStore.currentBoostRate)

const { isClient } = useApp()
const expanded = ref(false)
</script>

<template>
  <div class="hc-booster-banner">
    <div class="row items-center no-wrap">
      <div class="hc-booster-banner__multiplier">
        <img class="hc-booster-banner__multiplier__img" :src="tokenSvg" alt="Early-bird booster">
        <span class="hc-booster-banner__multiplier__amount q-ml-xs">{{ currentBoostRate }}×</span>
      </div>
      <div class="hc-booster-banner__title q-ml-sm">
        {{ $t('hc.boosterBanner.title') }}
      </div>
    </div>

    <div class="hc-booster-banner__subtitle q-mt-md">
      {{ $t('hc.boosterBanner.text') }}
    </div>

    <q-expansion-item
      v-model="expanded"
      class="hc-booster-banner__expansion"
    >
      <template #header>
        <q-item-section side>
          <i-app-custom-simple-arrow class="hc-booster-banner__expansion__arrow" />
        </q-item-section>
      </template>
      <hc-booster-chart v-if="isClient" />
      <div class="row no-wrap">
        <div class="hc-booster-banner__pre-tip">
          *
        </div>
        <div class="hc-booster-banner__tip q-mt-sm">
          {{ $t('hc.boosterBanner.tip1') }}
        </div>
      </div>

      <div class="row no-wrap">
        <div class="hc-booster-banner__pre-tip">
          **
        </div>
        <div class="hc-booster-banner__tip">
          {{ $t('hc.boosterBanner.tip2') }}
        </div>
      </div>
      <div class="row no-wrap">
        <div class="hc-booster-banner__pre-tip">
          ***
        </div>
        <div class="hc-booster-banner__tip">
          {{ $t('hc.boosterBanner.tip3') }}
        </div>
      </div>
    </q-expansion-item>
  </div>
</template>
